import * as React from "react";
import { twMerge } from "tailwind-merge";

interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  align?: "center" | "inherit" | "justify" | "left" | "right";
  color?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  display?: "inline" | "block" | "";
  gutterBottom?: boolean;
  noWrap?: boolean;
  paragraph?: boolean;
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "caption"
    | "body1"
    | "body2"
    | "inherit";
  component?: React.ElementType;
}

const defaultVariantMapping = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "h6",
  subtitle2: "h6",
  caption: "p",
  body1: "p",
  body2: "p",
  inherit: "p",
};

const classes = {
  variant: {
    h1: "text-4xl font-semibold tracking-tight",
    h2: "text-3xl font-semibold tracking-tight",
    h3: "text-2xl font-semibold",
    h4: "text-xl font-semibold",
    h5: "text-base font-semibold",
    h6: "text-sm font-semibold",
    subtitle1: "text-base leading-relaxed",
    subtitle2: "text-sm",
    caption: "text-sm",
    body1: "text-base",
    body2: "text-sm",
    inherit: "inherit",
  },
  color: {
    initial: "text-inherit",
    inherit: "text-inherit",
    primary: "text-purp-500",
    secondary: "text-purp-500",
    textPrimary: "dark:text-neutral-900 text-neutral-50",
    textSecondary: "dark:text-neutral-600 text-neutral-400",
    error: "text-red-500",
  },
};

export const Typography = React.forwardRef<HTMLElement, TypographyProps>(
  (
    {
      align = "inherit",
      color = "initial",
      display = "",
      className,
      component,
      gutterBottom = false,
      noWrap = false,
      paragraph = false,
      variant = "body1",
      ...restProps
    },
    ref
  ) => {
    const Component =
      component ||
      (paragraph
        ? "p"
        : defaultVariantMapping[variant] || defaultVariantMapping[variant]) ||
      "span";

    return (
      <Component
        className={twMerge(
          classes.variant[variant],
          classes.color[color],
          align !== "inherit" && `text-${align}`,
          gutterBottom && "mb-2",
          noWrap && "truncate",
          display,
          className
        )}
        ref={ref}
        {...restProps}
      />
    );
  }
);
