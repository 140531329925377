import * as React from "react";
import { twMerge } from "tailwind-merge";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  component?: "button";
  disabled?: boolean;
}

interface AnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  component: "a";
  href?: string;
}

type Props = ButtonProps | AnchorProps;

export const ButtonBase = React.forwardRef<any, Props>(
  ({ className, component, disabled = false, ...restProps }, ref) => {
    let Component;
    {
      if (component) {
        Component = component;
      } else if (restProps.href) {
        Component = "a";
      } else {
        Component = "button";
      }
    }

    const props: any = restProps;

    if (Component === "button") {
      props["type"] = props["type"] ?? "button";
    }

    return (
      <Component
        className={twMerge(
          "inline-flex items-center justify-center align-middle text-inherit",
          disabled && "pointer-events-none",
          className
        )}
        ref={ref}
        disabled={disabled}
        aria-disabled={disabled}
        {...props}
      >
        {props.children}
      </Component>
    );
  }
);
