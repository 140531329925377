import axios from "axios";

axios.defaults.xsrfCookieName = "CSRF-TOKEN";

axios.defaults.xsrfHeaderName = "X-CSRF-Token";

axios.defaults.withCredentials = true;

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response && response.data && response.data.error) {
      const httpError = new Error(response.data.error);
      httpError.response = response;
      return Promise.reject(httpError);
    }

    return Promise.reject(error);
  }
);

export default axios;
