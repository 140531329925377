import * as React from "react";
import { twMerge } from "tailwind-merge";
import { ButtonBase } from "./ButtonBase";

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  size?: "small" | "medium" | "large";
}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, disabled = false, size = "medium", ...restProps }, ref) => {
    return (
      <ButtonBase
        className={twMerge(
          "rounded-full hover:bg-white/5 focus:outline-none disabled:opacity-60",
          size === "small" && "p-1",
          size === "medium" && "p-2",
          size === "large" && "p-3",
          className
        )}
        disabled={disabled}
        ref={ref}
        {...restProps}
      >
        {restProps.children}
      </ButtonBase>
    );
  }
);
